export const manufactureConstants = {
    GETALL_REQUEST_EQUIPMENT: "GETALL_REQUEST_EQUIPMENT",
    GETALL_SUCCESS_EQUIPMENT: "GETALL_SUCCESS_EQUIPMENT",
    GETALL_FAILURE_EQUIPMENT: "GETALL_FAILURE_EQUIPMENT",

    GETBYID_REQUEST_EQUIPMENT: "GETBYID_REQUEST_EQUIPMENT",
    GETBYID_SUCCESS_EQUIPMENT: "GETBYID_SUCCESS_EQUIPMENT",
    GETBYID_FAILURE_EQUIPMENT: "GETBYID_FAILURE_EQUIPMENT",

    UPDATE_REQUEST_EQUIPMENT: "UPDATE_REQUEST_EQUIPMENT",
    UPDATE_SUCCESS_EQUIPMENT: "UPDATE_SUCCESS_EQUIPMENT",
    UPDATE_FAILURE_EQUIPMENT: "UPDATE_FAILURE_EQUIPMENT",

    CREATE_REQUEST_EQUIPMENT: "CREATE_REQUEST_EQUIPMENT",
    CREATE_SUCCESS_EQUIPMENT: "CREATE_SUCCESS_EQUIPMENT",
    CREATE_FAILURE_EQUIPMENT: "CREATE_FAILURE_EQUIPMENT",

    ARCHIVE_REQUEST_EQUIPMENT: "ARCHIVE_REQUEST_EQUIPMENT",
    ARCHIVE_SUCCESS_EQUIPMENT: "ARCHIVE_SUCCESS_EQUIPMENT",
    ARCHIVE_FAILURE_EQUIPMENT: "ARCHIVE_FAILURE_EQUIPMENT",

    DELETE_REQUEST_EQUIPMENT: "DELETE_REQUEST_EQUIPMENT",
    DELETE_SUCCESS_EQUIPMENT: "DELETE_SUCCESS_EQUIPMENT",
    DELETE_FAILURE_EQUIPMENT: "DELETE_FAILURE_EQUIPMENT",

//////////////////////////////////////////////////////////////////////////////////////////
    GETALL_REQUEST_MANUFACTURE: "GETALL_REQUEST_MANUFACTURE",
    GETALL_SUCCESS_MANUFACTURE: "GETALL_SUCCESS_MANUFACTURE",
    GETALL_FAILURE_MANUFACTURE: "GETALL_FAILURE_MANUFACTURE",

    GETBYID_REQUEST_MANUFACTURE: "GETBYID_REQUEST_MANUFACTURE",
    GETBYID_SUCCESS_MANUFACTURE: "GETBYID_SUCCESS_MANUFACTURE",
    GETBYID_FAILURE_MANUFACTURE: "GETBYID_FAILURE_MANUFACTURE",

    UPDATE_REQUEST_MANUFACTURE: "UPDATE_REQUEST_MANUFACTURE",
    UPDATE_SUCCESS_MANUFACTURE: "UPDATE_SUCCESS_MANUFACTURE",
    UPDATE_FAILURE_MANUFACTURE: "UPDATE_FAILURE_MANUFACTURE",

    CREATE_REQUEST_MANUFACTURE: "CREATE_REQUEST_MANUFACTURE",
    CREATE_SUCCESS_MANUFACTURE: "CREATE_SUCCESS_MANUFACTURE",
    CREATE_FAILURE_MANUFACTURE: "CREATE_FAILURE_MANUFACTURE",

    ARCHIVE_REQUEST_MANUFACTURE: "ARCHIVE_REQUEST_MANUFACTURE",
    ARCHIVE_SUCCESS_MANUFACTURE: "ARCHIVE_SUCCESS_MANUFACTURE",
    ARCHIVE_FAILURE_MANUFACTURE: "ARCHIVE_FAILURE_MANUFACTURE",

    DELETE_REQUEST_MANUFACTURE: "DELETE_REQUEST_MANUFACTURE",
    DELETE_SUCCESS_MANUFACTURE: "DELETE_SUCCESS_MANUFACTURE",
    DELETE_FAILURE_MANUFACTURE: "DELETE_FAILURE_MANUFACTURE",
};

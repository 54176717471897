import React from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Controller } from "react-hook-form";

const ComboBox = (props) => {
  const { name, control, label, options, variant, margin, emptyMessage, required, errors, loading, disableClearable, defaultValue, disabled } = props;

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue ? defaultValue : null}
      render={({ field }) => (
        <Autocomplete
          {...field}
          options={options}
          getOptionLabel={(option) => option.title || ""}
          getOptionSelected={(option, value) => option.value === value?.value}
          openText="Открыть"
          closeText="Закрыть"
          clearText="Очистить"
          noOptionsText={emptyMessage ? emptyMessage : "Нет данных"}
          loading={loading}
          loadingText="Загрузка..."
          fullWidth
          disableClearable={disableClearable ? true : false}
          disabled={disabled ? disabled : false}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              variant={variant ? variant : "outlined"}
              margin={margin ? margin : "dense"}
              required={required ? required : false}
              error={errors ? true : false}
              helperText={errors?.message}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(_, data) => field.onChange(data)}
        />
      )}
    />
  );
};

export default React.memo(ComboBox);

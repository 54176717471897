import { documentConstants } from "../../constants";

const defaultState = {
  items: [],
  item: {},
  undelivered: [],
  loadingItems: false,
  loadingItem: false,
  loadingUndelivered: false,
  fetchedItems: false,
  fetchedItem: false,
  fetchedUndelivered: false,
  error: null,
};

function normalData(arr) {
  if (!Array.isArray(arr)) return [];

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: index + 1,
      whoCreated: typeof elem.whoCreated === 'object' ? elem.whoCreated.firstName + " " + elem.whoCreated.lastName : elem.whoCreated,
    })
  );
}

function normalUndelivered(arr) {
  if (!Array.isArray(arr)) return [];

  return arr.map((elem, index) =>
    Object.assign(elem, {
      index: index + 1,
      article: elem.nomenclature.article,
      nomenclature: elem.nomenclature.nameNomenclature,
      nomenclatureId: elem.nomenclature.id,
      category: elem.nomenclature.category ? elem.nomenclature.category.nameCategory : "Не указано",
      unit: elem.nomenclature.unit ? elem.nomenclature.unit.nameUnit : "Не указано",
      supplierId: elem.supplier.id,
      supplier: elem.supplier.name,
    })
  );
}

export const comingReducer = function (state = defaultState, action) {
  switch (action.type) {
    case documentConstants.SET_ITEM_LOADING_COMING:
      return {
        ...state,
        loadingItem: action.isLoading,
      };
    case documentConstants.GETALL_REQUEST_COMING:
      return {
        ...state,
        item: {},
        loadingItems: true,
        loadingItem: false,
        fetchedItems: false,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.GETALL_SUCCESS_COMING:
      return {
        ...state,
        items: normalData(action.response),
        loadingItems: false,
        fetchedItems: true,
      };
    case documentConstants.GETALL_FAILURE_COMING:
      return {
        ...state,
        loadingItems: false,
        fetchedItems: false,
        error: action.error,
      };

    case documentConstants.GETBYID_REQUEST_COMING:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.GETBYID_SUCCESS_COMING:
      return {
        ...state,
        item: action.response,
        loadingItem: false,
        fetchedItem: true,
      };
    case documentConstants.GETBYID_FAILURE_COMING:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.CREATE_REQUEST_COMING:
      return {
        ...state,
        item: {},
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.CREATE_SUCCESS_COMING:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(state.items.concat(action.response)),
      };
    case documentConstants.CREATE_FAILURE_COMING:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.UPDATE_REQUEST_COMING:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.UPDATE_SUCCESS_COMING:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: true,
        item: action.response,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.response.id) return Object.assign(elem, { ...action.response });
            return elem;
          })
        ),
      };
    case documentConstants.UPDATE_FAILURE_COMING:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        error: action.error,
      };

    case documentConstants.ARCHIVE_REQUEST_COMING:
      return {
        ...state,
        loadingItem: true,
        fetchedItem: false,
        error: null,
      };
    case documentConstants.ARCHIVE_SUCCESS_COMING:
      return {
        ...state,
        loadingItem: false,
        fetchedItem: false,
        items: normalData(
          state.items.map((elem) => {
            if (elem.id === action.id)
              return Object.assign(elem, { ...action.response, isArchive: !elem.isArchive });
            return elem;
          })
        ),
      };
    case documentConstants.ARCHIVE_FAILURE_COMING:
      return {
        ...state,
        loadingItem: false,
        error: action.error,
      };

    case documentConstants.UNDELIVERED_REQUEST_COMING:
      return {
        ...state,
        loadingUndelivered: true,
        error: null,
      };
    case documentConstants.UNDELIVERED_SUCCESS_COMING:
      return {
        ...state,
        undelivered: normalUndelivered(action.response),
        loadingUndelivered: false,
        fetchedUndelivered: true,
      };
    case documentConstants.UNDELIVERED_FAILURE_COMING:
      return {
        ...state,
        loadingUndelivered: false,
        fetchedUndelivered: false,
        error: action.error,
      };

    case "RESET_ITEM_COMING":
      return {
        ...state,
        fetchedItem: false,
        loadingItem: false,
        item: {},
      };

    case "RESET_ITEMS_COMING":
      return {
        ...state,
        fetchedItems: false,
        loadingItems: false,
        items: [],
        error: null,
      };
    default:
      return state;
  }
};
